import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NewsPreferences from "./Pages/NewsPrefrences";
import AthenaAppBar from "./Components/AppBar";
import NewsRedirect from "./Pages/NewsRedirect";
import NewsPanel from "./Pages/NewsPanel";

function App() {
  return (
    <>
    <AthenaAppBar />
    <BrowserRouter>
      <Routes>
        <Route path="/news-preferences/:id" element={<NewsPreferences />} />
        <Route path="/:id" element={<NewsRedirect />} />
        <Route path="/news/:id" element={<NewsPanel />} />
      </Routes>
    </BrowserRouter>
    </>

  );
}

export default App;
