import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import constants from "../config";
import { CardContent, CircularProgress, Stack } from "@mui/material";

const NewsRedirect = () => {
  const { id } = useParams();
  const redirectUser = () => {
    axios
      .post(`${constants.BASE_URL}news/news-panel/get-news-url/${id}`)
      .then((response) => {
        console.log(response);
        const {
          data: { newsUrl },
        } = response;
        window.location.href = newsUrl;
      })
      .catch((error) => {
        console.log("Error=>>", error);
      });
  };
  useEffect(() => {
    redirectUser();
  }, []);

  return (
    <Stack direction="row" sx={{ justifyContent: "center", mt: "5%" }}>
      <CardContent>
        <CircularProgress />
      </CardContent>
      <CardContent sx={{ fontSize: "20px", fontWeight: "bold" }}>
        Redirecting....
      </CardContent>
    </Stack>
  );
};

export default NewsRedirect;
