import {
  Box,
  Button,
  Card,
  CardContent,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import Error404Page from "./Error404Page";
import constants from "../config";
import Error404Page from "./Error404Page";

const NewsPanel = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [page, setPage] = useState(1);
  const limitPerPage = 10;
  const [newsData, setNewsData] = useState([]);
  const [newsCount, setNewsCount] = useState(10);
  const [userFound, setUserFound] = useState(true);
  useEffect(() => {
    axios
      .post(
        `${constants.BASE_URL}news/news-panel/get-news/${id}?page=${page}&limitPerPage=${limitPerPage}`
      )
      .then((res) => {
        setNewsData(res.data.data);
        setUserFound(true);
      })
      .catch((err) => {
        setUserFound(false);
        console.log("Error=>", err);
      });
  }, [page]);

  useEffect(() => {
    axios
      .post(`${constants.BACKEND_BASE_URL}news/news-panel/get-news-count/${id}`)
      .then((res) => {
        setUserFound(true);
        setNewsCount(res.data.data);
      })
      .catch((err) => {
        setUserFound(false);
        console.log("Error=>", err);
      });
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
  };
  return (
    <>
      {userFound ? (
        <Box sx={{ m: "10px 10px" }}>
          <Stack spacing={2}>
            {newsData.map((news) => (
              <Card>
                <CardContent>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {news.title}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography>{news.text}</Typography>
                </CardContent>
                <CardContent>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Tickers:
                    </Typography>
                    {news.tickers.map((ticker) => (
                      <Typography sx={{ fontWeight: "bold" }}>
                        {ticker}
                      </Typography>
                    ))}
                  </Stack>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      component={Link}
                      to={news.news_url}
                      target="_blank"
                    >
                      Read More
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
            <Pagination
              count={Math.floor(newsCount / limitPerPage)}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Error404Page />
        </>
      )}
    </>
  );
};

export default NewsPanel;
