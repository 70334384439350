import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  CardContent,
  Chip,
  Container,
  LinearProgress,
  List,
  ListItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import constants from "../config";
import Error404Page from "./Error404Page";

const NewsPreferences = () => {
  const { id } = useParams();
  const [auth, setAuth] = useState(true);
  const [tickersList, setTickersList] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(null);
  const searchTimerRef = useRef(null);
  const [loading, setloading] = useState(false);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const fetchSubscribedTickers = async () => {
    console.log(constants.GET_SUBSCRIBED_TICKERS_API);
    try {
      const response = await axios.post(constants.GET_SUBSCRIBED_TICKERS_API, {
        id: id,
      });
      if (response) {
        setSelectedTickers([...response.data.data]);
        setAuth(true);
      }
    } catch (error) {
      setAuth(false);
      console.log("Error in fetching Subscribed Tickers list", error);
    }
  };
  useEffect(() => {
    fetchSubscribedTickers();
  }, []);

  const fetchSearchData = async (search) => {
    try {
      setloading(true);
      const response = await axios.post(
        `${constants.BASE_URL}news/news-preference/ticker?search=${search}`
      );
      return response.data.data;
    } catch (error) {
      console.log("Error", error);
      return [];
    }
  };
  useEffect(() => {
    setloading(false);
  }, [tickersList]);

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      clearTimeout(searchTimerRef.current);

      searchTimerRef.current = setTimeout(async () => {
        const searchData = await fetchSearchData(debouncedSearchTerm);
        setTickersList([...searchData]);
      }, 500);
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setDebouncedSearchTerm(inputValue);
  };

  return (
    <>
      {auth ? (
        <Container>
          <CardContent
            sx={{ fontWeight: "bold", fontSize: { xs: "6vw", lg: "4vw" } }}
          >
            Select your news preference
          </CardContent>
          <CardContent>
            <Stack spacing={2}>
              <TextField
                sx={{ width: "300px" }}
                onChange={(e) => {
                  if (e.target.value.length > 1) {
                    handleInputChange(e);
                  }
                  setTickersList([]);
                }}
                label="Tickers"
                placeholder="News Preference"
              />
              <List
                sx={{
                  width: "100%",
                  maxWidth: 300,
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: 300,
                  "& ul": { padding: 0 },
                }}
                subheader={<li />}
              >
                {loading ? (
                  <LinearProgress />
                ) : (
                  tickersList.map((item) => (
                    <li key={`section-${item.ticker}`}>
                      <ul>
                        <ListItem key={`${item.ticker}`}>
                          <CardContent
                            sx={{
                              fontWeight: "bold",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              },
                              cursor: "pointer",
                            }}
                          >
                            <Typography
                              variant="text"
                              color="success"
                              onClick={() => {
                                setSelectedTickers([...selectedTickers, item]);
                              }}
                            >
                              {`${item.ticker} - ${item.name}`}
                            </Typography>
                          </CardContent>
                        </ListItem>
                      </ul>
                    </li>
                  ))
                )}
              </List>
            </Stack>
          </CardContent>
          <CardContent>
            <Stack direction="row" spacing={2} sx={{ flexWrap: "wrap" }}>
              {selectedTickers.map((ticker) => (
                <Chip
                  key={ticker.ticker}
                  label={ticker.ticker}
                  onDelete={() => {
                    setSelectedTickers((tickers) => {
                      return tickers.filter(
                        (item) => item.ticker !== ticker.ticker
                      );
                    });
                  }}
                />
              ))}
            </Stack>
          </CardContent>
          <CardContent
            variant="contained"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  await axios.post(constants.SAVE_TICKERS_API, {
                    id: id,
                    tickers: selectedTickers,
                  });
                  setOpenSnackBar(true);
                } catch (error) {
                  console.error("Error saving preferences:", error);
                }
              }}
            >
              Save Preference
            </Button>
          </CardContent>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="success"
              sx={{ width: "100%" }}
            >
              News preference saved successfully
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <Container>
          <Error404Page />
        </Container>
      )}
    </>
  );
};

export default NewsPreferences;
