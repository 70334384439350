import { AppBar, Box, CardMedia, IconButton, Toolbar } from "@mui/material";
import React from "react";
import athena_main_logo from "../Utilities/Images/athena_main_logo.PNG";

const AthenaAppBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          ></IconButton>
          <Box
            sx={{
              display: "flex",
              height: "100px",
              width: "120px",
              alignItems: "center",
            }}
          >
            <CardMedia component="img" src={athena_main_logo} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AthenaAppBar;
